import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO from 'components/seo';
import get from 'lodash.get';
import GHMLandingPage from 'components/CustomLanding/GoodHairMagic/GoodHairMagicLandingPage';

export default function GoodHairMagicLandingPage(props) {
    let {
        data: {
            contentfulCustomLandingPage,
            allShopifyProduct: { nodes: allShopifyProducts },
            allGoogleSheetProductsRow,
            contentfulProduct,
        },
    } = props;

    return (
        <Layout adLanding>
            <SEO
                description={get(
                    contentfulCustomLandingPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulCustomLandingPage, 'seoListing.seoTitle')}
                slug={'good-hair-magic'}
                isPro={false}
            />
            <GHMLandingPage
                page={contentfulCustomLandingPage}
                allShopifyProducts={allShopifyProducts}
                allGoogleSheetProductsRow={allGoogleSheetProductsRow}
                contentfulProduct={contentfulProduct}
                isPro={false}
            />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulCustomLandingPage(
            node_locale: { eq: $locale }
            slug: { eq: "good-hair-magic" }
        ) {
            ...ContentfulCustomLandingPageFields
            tabs {
                title
                ctaUrl
                ctaText
                icon {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        allShopifyProduct {
            nodes {
                ...ShopifyProductFields
            }
        }
        allGoogleSheetProductsRow {
            nodes {
                sku
                caAssortment
                canIdh
            }
        }
    }
`;
